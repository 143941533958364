/*----------------------
     MixIns
-----------------------*/

.vertical-align {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.horizontal-align {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

/*----------------------
     Responsive
-----------------------*/

@media only screen and (max-width: 767px) {
    .mobile.hidden,
    .tablet.only,
    .small.monitor.only,
    .large.monitor.only {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .mobile.only,
    .tablet.hidden,
    .small.monitor.only,
    .large.monitor.only {
        display: none !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .mobile.only,
    .tablet.only,
    .small.monitor.hidden,
    .large.monitor.only {
        display: none !important;
    }
}

@media only screen and (min-width: 1200px) {
    .mobile.only,
    .tablet.only,
    .small.monitor.only,
    .large.monitor.hidden {
        display: none !important;
    }
}

.info {
    font-size: 18px;
}

/*----------------------
     AppStyles
-----------------------*/

#app {

    /*----------------------
         Cookie Nag
    -----------------------*/

    .ui.nag {
        border-radius: 0;
        background: #2e2e2e none repeat scroll 0 0;

        > .close.icon {
            font-size: 1.3em;
            opacity: 0.8;

            &:hover {
                opacity: 1.0;
            }
        }
    }

    /*----------------------
         Flash Messages
    -----------------------*/

    .ui.session.message {
        position: fixed;
        z-index: 99;

        .vertical-align;
        .horizontal-align;
    }

    /*----------------------
         Grids
    -----------------------*/

    .ui.boxed.grid {
        .column {
            max-width: 800px;
        }
    }

    @media only screen and (min-width: 800px) {
        .ui.boxed.grid {
            height: 100%;
            justify-content: center;
        }
    }

    /*----------------------
         Labels
    -----------------------*/
    //.ui.label {
    //    font-weight: 100;
    //}

    /*----------------------
         Forms
    -----------------------*/
    input.uppercase {
        text-transform: uppercase;
    }

    /*----------------------
         Helpers
    -----------------------*/
    .roundless {
        border-radius: 0 !important;
    }

}